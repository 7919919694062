import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

const BASE_URI = '/apis/performance-checks';
const OPERATIONS_BASE_URI = BASE_URI + '/operations';
export const PerformanceCheckService = (function () {
  let instance;
  const baseService = BaseService.getInstance(BASE_URI, 'performanceCheck');

  function init() {
    return {
      ...baseService,
      registerBase: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI + '/data/base', data);
      },
      updateBase: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI + '/data/base', data);
      },
      registerDetail: async function (data, files) {
        return await AxiosUtil.Async.multipart(
          BASE_URI + '/data/detail',
          data,
          files
        );
      },
      registerRecheckBase: async function (data) {
        return await AxiosUtil.Async.post(
          BASE_URI + '/data/recheck/base',
          data
        );
      },
      updateRecheckBase: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI + '/data/recheck/base', data);
      },
      getData: async function (statementNumber) {
        return await AxiosUtil.Async.get(BASE_URI + `/${statementNumber}`);
      },
      getDataMobileByStatementNumber: async function (statementNumber) {
        return await AxiosUtil.Async.get(
          BASE_URI + `/viewer/${statementNumber}`
        );
      },
      getDataMobileByDataId: async function (dataId) {
        return await AxiosUtil.Async.get(BASE_URI + `/viewer/data/${dataId}`);
      },
      getDataMobileByLpns: async function (licensePlateNumber) {
        return await AxiosUtil.Async.get(
          BASE_URI + `/viewer/lpn/${licensePlateNumber}`
        );
      },
      getBase64Url: async function (imgUrl) {
        const encodedImgUrl = encodeURIComponent(imgUrl);
        const queryString = `imageUrl=${encodedImgUrl}`;
        return await AxiosUtil.Async.get(
          '/office/rest/extservice/base64encoding',
          queryString
        );
      },
      delete: async function (statementNumber) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${statementNumber}`);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getDeletedList: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        return await AxiosUtil.Async.get(BASE_URI + '/delete', queryString);
      },
      updatePayment: async function (data) {
        return await AxiosUtil.Async.put(
          OPERATIONS_BASE_URI + '/payment',
          data
        );
      },
      updateNote: async function (data) {
        return await AxiosUtil.Async.post(OPERATIONS_BASE_URI + '/note', data);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const Operations = {
  countPrint: async (dataId, count) => {
    const res = await axios.put(`${OPERATIONS_BASE_URI}/print`, null, {
      params: {
        dataId: Number(dataId),
        count: count,
      },
    });
    return res.data.data;
  },
};

const BASE_URI_R = '/apis/repair';
export const RepairApi = {
  getCenter: async (lpns, regDate) => {
    const result = await axios.get(`${BASE_URI_R}/center`, {
      params: {
        lpns: lpns,
        regDate: regDate,
      },
    });
    return result.data.data;
  },
  getInsurance: async (lpns) => {
    const result = await axios.get(`${BASE_URI_R}/insurance`, {
      params: {
        lpns: lpns,
      },
    });
    return result.data.data;
  },
};
