import {
  COUNT_PER_PAGE,
  MESSAGE_STATUS,
  REPORT_TYPE,
  YN,
} from '../../constants/Constants';
import {
  PC_BASE_BLUEPRINT,
  PC_VALID_DAYS,
} from '../../constants/PerformanceCheckConstants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  columnCheckState,
  columnNumberWithCommas,
  columnTransferState,
} from '../../utils/dataTableUtil';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Button } from 'primereact/button';
import CheckBaseDataDialog from '../../components/PerformanceCheck/CheckBaseDataDialog';
import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import RangeCalendar from '../../components/Common/RangeCalendar';
import RecheckBaseDataDialog from '../../components/PerformanceCheck/RecheckBaseDataDialog';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { Tag } from 'primereact/tag';
import { TieredMenu } from 'primereact/tieredmenu';
import TransferState from '../../enums/TransferState';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';

const today = dayjs();
const startDate = today.subtract(1, 'month');
const endDate = today;

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  startDate: startDate.toDate(),
  endDate: endDate.toDate(),
  statementNumber: '',
  carSearchKeyword: '',
  customerSearchBy: '1',
  customerSearchKeyword: '',
  checkType: '',
  payYN: '',
  delYN: YN.NO,
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const performanceCheckService = ServiceProvider.performanceCheck;

const PerformanceCheckList = () => {
  const touchUI = useRecoilValue(touchUIState);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const myInfo = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const defaultSearchConditions = useMemo(() => {
    const { myAssociationId, myEnterpriseId, myShopId, myRoleCode } = myInfo;
    const permissions = {
      delete: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      period: _.filter(UserRoleType, function (r) {
        return r.value === 'CERP_ADM' || _.startsWith(r.value, 'A_');
      }),
    };
    const deleteAuth =
      _.findIndex(permissions.delete, { value: myRoleCode }) >= 0;
    const periodAuth =
      _.findIndex(permissions.period, { value: myRoleCode }) >= 0;

    return {
      ...DEFAULT_SEARCH_CONDITIONS,
      startDate: today.subtract(1, periodAuth ? 'month' : 'year').toDate(),
      delYN: deleteAuth ? '' : YN.NO,
      associations: myAssociationId ? [myAssociationId] : [],
      enterprises: myEnterpriseId ? [myEnterpriseId] : [],
      shops: myShopId ? [myShopId] : [],
    };
  }, [myInfo]);

  const [searchConditions, setSearchConditions] = useState({
    ...defaultSearchConditions,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  const menu = useRef(null);
  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    performanceCheckService,
    searchConditions,
    setSearchConditions,
    defaultSearchConditions,
    lazyParams.page,
    lazyParams.rows
  );

  const [currentStatementNumber, setCurrentStatementNumber] = useState(null);
  const [currentDataId, setCurrentDataId] = useState(null);
  const [originStatementNumber, setOriginStatementNumber] = useState(null);
  const [codes, setCodes] = useState({});
  const [exporting, setExporting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [menuItem, setMenuItem] = useState(null);

  async function exportToExcel() {
    setExporting(true);

    const params = {
      ...searchConditions,
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    };
    params.startDate = dayjs(searchConditions.startDate).format('YYYYMMDD');
    params.endDate = dayjs(searchConditions.endDate).format('YYYYMMDD');

    await performanceCheckService.exportExcel(params);

    setExporting(false);
  }

  function checkAuth(userInfo, type) {
    const { roleCode } = userInfo;

    const ACCESS_PERMISSION = {
      regist: _.filter(UserRoleType, function (r) {
        return (
          _.startsWith(r.value, 'P') ||
          _.startsWith(r.value, 'S') ||
          r.value === 'A_MST' ||
          r.value === 'CERP_ADM' ||
          r.value === 'E_OFF'
        );
      }),
      update: _.filter(UserRoleType, function (r) {
        return (
          _.startsWith(r.value, 'C_') ||
          r.value === 'A_OFF' ||
          r.value === 'E_ADM'
        );
      }),
      getDeleteList: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' || r.value === 'A_MST' || r.value === 'A_ADM'
        );
      }),
      getMonthlyList: _.filter(UserRoleType, function (r) {
        return r.value === 'CERP_ADM' || _.startsWith(r.value, 'A_');
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    setSearchConditions((ps) => ({ ...ps, ...defaultSearchConditions }));
    window.getListByChildren = async function () {
      setLazyParams({ ...DEFAULT_LAZY_PARAMS });
      await getList(
        defaultSearchConditions,
        _.get(DEFAULT_LAZY_PARAMS, 'page'),
        _.get(DEFAULT_LAZY_PARAMS, 'rows')
      );
    };

    return () => {
      delete window.getListByChildren;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchConditions]);

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const { myAssociationId, myEnterpriseId, myShopId } = myInfo;

        const [associations, associationId, association] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId, enterprise] =
          await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          });
        const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });
        setCodes((ps) => ({ ...ps, associations, enterprises, shops }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.contents]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => {
                      setLazyParams(DEFAULT_LAZY_PARAMS);
                      initList();
                      setSelectedRows([]);
                    }}
                    onSearch={() => {
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      );
                      setSelectedRows([]);
                      setLazyParams(DEFAULT_LAZY_PARAMS);
                    }}
                  >
                    <div
                      className={classNames('field lg:col-4 md:col-12', {
                        'sm:col-6 md:col-12 lg:col-4': checkAuth(
                          myInfoLoadable.contents,
                          'getDeleteList'
                        ),
                        'sm:col-6 md:col-12 lg:col-8': !checkAuth(
                          myInfoLoadable.contents,
                          'getDeleteList'
                        ),
                      })}
                    >
                      <label htmlFor="range">성능점검일자</label>
                      <RangeCalendar
                        startDate={searchConditions.startDate}
                        endDate={searchConditions.endDate}
                        onStartDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            startDate: date,
                          }));
                        }}
                        onEndDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            endDate: date,
                          }));
                        }}
                        touchUI={touchUI}
                        showNavigators={true}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 md:col-4 lg:col-4">
                      <label htmlFor="checkType">점검종류</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_checkType_all"
                            name="checkType"
                            value=""
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                checkType: e.value,
                              }));
                            }}
                            checked={searchConditions.checkType === ''}
                          />
                          <label htmlFor="radio_checkType_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_checkType_new"
                            name="checkType"
                            value="N"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                checkType: e.value,
                              }));
                            }}
                            checked={searchConditions.checkType === 'N'}
                          />
                          <label htmlFor="radio_checkType_new">신규성능</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_checkType_re"
                            name="checkType"
                            value="Y"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                checkType: e.value,
                              }));
                            }}
                            checked={searchConditions.checkType === 'Y'}
                          />
                          <label htmlFor="radio_checkType_re">재성능</label>
                        </div>
                      </div>
                    </div>

                    <div
                      className={classNames('field col-12', {
                        'sm:col-6 md:col-4 lg:col-4': checkAuth(
                          myInfoLoadable.contents,
                          'getDeleteList'
                        ),
                        hidden: !checkAuth(
                          myInfoLoadable.contents,
                          'getDeleteList'
                        ),
                      })}
                    >
                      <>
                        <label htmlFor="delYN">삭제여부</label>
                        <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                          <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                            <RadioButton
                              inputId="radio_delYn_a"
                              name="delYN"
                              value=""
                              onChange={(e) => {
                                setSearchConditions((ps) => ({
                                  ...ps,
                                  delYN: e.value,
                                }));
                                // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                              }}
                              checked={searchConditions.delYN === ''}
                            />
                            <label htmlFor="radio_delYn_a">전체</label>
                          </div>
                          <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                            <RadioButton
                              inputId="radio_delYn_y"
                              name="delYN"
                              value="Y"
                              onChange={(e) => {
                                setSearchConditions((ps) => ({
                                  ...ps,
                                  delYN: e.value,
                                }));
                                // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                              }}
                              checked={searchConditions.delYN === 'Y'}
                            />
                            <label htmlFor="radio_delYn_y">삭제</label>
                          </div>
                          <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                            <RadioButton
                              inputId="radio_delYn_n"
                              name="delYN"
                              value="N"
                              onChange={(e) => {
                                setSearchConditions((ps) => ({
                                  ...ps,
                                  delYN: e.value,
                                }));
                                // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                              }}
                              checked={searchConditions.delYN === 'N'}
                            />
                            <label htmlFor="radio_delYn_n">미삭제</label>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="associations">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(enterprises),
                            });

                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="enterprises">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });

                          handleSelectAll(
                            value,
                            'enterprises',
                            {
                              shops,
                            },
                            {
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="shops">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="customerSearchKeyword">고객</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.customerSearchKeyword}
                        placeholder={
                          searchConditions.customerSearchBy === '1'
                            ? '고객 이름으로 검색'
                            : '매매상사 이름, 대표전화, 사업자등록번호로 검색'
                        }
                        onChange={(e) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            customerSearchKeyword: e.target.value,
                          }));
                          // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                        }}
                      />
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: e.value,
                              }));
                              // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                            }}
                            checked={searchConditions.customerSearchBy === '1'}
                          />
                          <label htmlFor="radio_dealerName">고객</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_companyName"
                            name="customerSearchBy"
                            value="2"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: e.value,
                              }));
                              // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                            }}
                            checked={searchConditions.customerSearchBy === '2'}
                          />
                          <label htmlFor="radio_companyName">매매상사</label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="range">차량</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.carSearchKeyword}
                        placeholder="차량번호, 차대번호로 검색"
                        onChange={(e) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            carSearchKeyword: e.target.value,
                          }));
                          // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-4">
                      <label htmlFor="statementNumber">성능지번호</label>
                      <InputText
                        id="statementNumber"
                        className="w-full"
                        value={searchConditions.statementNumber}
                        onChange={(e) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            statementNumber: e.target.value,
                          }));
                          // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
                        }}
                      />
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-between gap-1">
                    <div className="flex gap-1"></div>
                    <div className="flex gap-1">
                      {checkAuth(myInfoLoadable.contents, 'regist') && (
                        <>
                          <Button
                            label="성능점검 신규 등록"
                            icon="pi pi-check-square"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => {
                              CommonUtil.Window.popup(
                                '/performance-check/form'
                              );
                            }}
                          />
                        </>
                      )}

                      <Button
                        label="엑셀 다운로드"
                        className="p-button-outlined"
                        icon="pi pi-download"
                        disabled={exporting || !data.length}
                        loading={exporting}
                        onClick={async () => await exportToExcel()}
                      />
                    </div>
                  </div>

                  <Panel className="shadow-1 mb-3">
                    <TieredMenu
                      ref={menu}
                      id="overlay_tmenu"
                      popup
                      model={
                        checkAuth(myInfoLoadable.contents, 'update')
                          ? [
                              {
                                label: '성능지 보기',
                                icon: 'pi pi-fw pi-file',
                                disabled: ![
                                  TransferState.Transfered,
                                  TransferState.Updated,
                                  TransferState.TransferReturn,
                                ].includes(_.get(menuItem, 'transferState')),
                                command: (event) => {
                                  const { statementNumber, dataId } = menuItem;
                                  CommonUtil.Window.popup(
                                    `/performance-check/${statementNumber}-${dataId}/viewer`,
                                    `pcv#${statementNumber}`,
                                    window.innerWidth * 0.8,
                                    window.innerHeight * 0.8
                                  );

                                  menu.current.hide(event);
                                },
                              },
                            ]
                          : [
                              {
                                label: '성능지 보기',
                                icon: 'pi pi-fw pi-file',
                                disabled: ![
                                  TransferState.Transfered,
                                  TransferState.Updated,
                                  TransferState.TransferReturn,
                                ].includes(_.get(menuItem, 'transferState')),
                                command: (event) => {
                                  const { statementNumber, dataId } = menuItem;
                                  CommonUtil.Window.popup(
                                    `/performance-check/${statementNumber}-${dataId}/viewer`,
                                    `pcv#${statementNumber}`,
                                    window.innerWidth * 0.8,
                                    window.innerHeight * 0.8
                                  );

                                  menu.current.hide(event);
                                },
                              },
                              {
                                label: '재성능 등록',
                                icon: 'pi pi-fw pi-clone',
                                disabled: !(
                                  _.get(menuItem, 'transferState') ===
                                    TransferState.Transfered ||
                                  _.get(menuItem, 'transferState') ===
                                    TransferState.Updated ||
                                  _.get(menuItem, 'transferState') ===
                                    TransferState.TransferReturn
                                ),

                                command: (event) => {
                                  const { statementNumber, dataId } = menuItem;
                                  setCurrentDataId(dataId);
                                  setOriginStatementNumber(statementNumber);
                                  menu.current.hide(event);
                                },
                              },
                            ]
                      }
                      onHide={() => setMenuItem(null)}
                    />
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      selection={selectedRows}
                      selectionMode="checkbox"
                      onSelectionChange={({ value }) => {
                        setSelectedRows(value);
                      }}
                      emptyMessage="데이터가 없습니다."
                      virtualScrollerOptions={{
                        itemSize:
                          lazyParams.rows < 100 ? lazyParams.rows / 2 : 100,
                      }}
                    >
                      <Column
                        field="dtn"
                        header="순번"
                        className="text-center"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="licensePlateNumber"
                        header={
                          <div className="w-10rem">
                            차량번호
                            <br />
                            차량모델
                          </div>
                        }
                        body={({ licensePlateNumber, carName }) => (
                          <div className="w-10rem">
                            {licensePlateNumber}
                            <br />
                            <p
                              className="w-10rem"
                              style={{
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                              }}
                            >
                              {carName}
                            </p>
                          </div>
                        )}
                      />
                      <Column
                        field="customerCompanyName"
                        header={
                          <div>
                            소속상사
                            <br />
                            고객명
                          </div>
                        }
                        body={({ customerCompanyName, customerName }) => (
                          <div>
                            {customerCompanyName}
                            <br />
                            {customerName}
                          </div>
                        )}
                      />
                      <Column
                        field="statementNumber"
                        header="성능지 번호"
                        body={({ dataId, statementNumber }) => (
                          <>
                            {checkAuth(myInfoLoadable.contents, 'update') ? (
                              <span>{statementNumber}</span>
                            ) : (
                              <a
                                href="#"
                                onClick={() => {
                                  setCurrentStatementNumber(statementNumber);
                                  setCurrentDataId(dataId);
                                }}
                              >
                                {statementNumber}
                              </a>
                            )}
                          </>
                        )}
                      />
                      <Column
                        field="statementNumber"
                        header={<i className="pi pi-bars" />}
                        body={(rowData) => (
                          <Button
                            icon="pi pi-bars"
                            className="p-button-sm p-button-outlined"
                            onClick={(event) => {
                              setMenuItem(rowData);
                              menu.current.toggle(event);
                            }}
                            aria-haspopup
                            aria-controls="overlay_tmenu"
                          />
                        )}
                      />
                      <Column
                        field="isRecheck"
                        header="재성능여부"
                        className="text-center"
                        body={({ isRecheck }) =>
                          isRecheck === YN.YES && (
                            <Tag className="text-sm" value="재성능" />
                          )
                        }
                      />

                      <Column
                        field="checkDate"
                        header="점검일자"
                        body={({ checkDate }) =>
                          `${dayjs(checkDate).format('YYYY-MM-DD')}`
                        }
                      />
                      <Column
                        field="carYear"
                        header={
                          <div>
                            차량연식
                            <br />
                            주행거리
                          </div>
                        }
                        className="text-right"
                        body={({ carYear, carDistance }) => (
                          <div>
                            {carYear} 년식
                            <br />
                            {columnNumberWithCommas(carDistance)} Km
                          </div>
                        )}
                      />
                      <Column
                        field="carVIN"
                        header="차대번호"
                        body={({ carVIN }) => <div>{carVIN}</div>}
                      />
                      <Column
                        field="checkState"
                        className="text-center"
                        header="점검상태"
                        body={({ checkState }) => columnCheckState(checkState)}
                      />
                      <Column
                        field="checkDateDiff"
                        header="경과일수"
                        className="text-center"
                        body={({ checkDateDiff }) => `${checkDateDiff}일`}
                      />
                      <Column
                        field="transferState"
                        header="전송상태"
                        className="text-center"
                        body={({ transferState }) =>
                          columnTransferState(transferState)
                        }
                      />
                      <Column
                        field="initialTransferTime"
                        header={
                          <div>
                            최초전송일시
                            <br />
                            최근전송일시
                          </div>
                        }
                        body={({ initialTransferTime, latestTransferTime }) => (
                          <div>
                            {initialTransferTime !== null
                              ? dayjs(initialTransferTime).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : ''}
                            <br />
                            {latestTransferTime !== null
                              ? dayjs(latestTransferTime).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : ''}
                          </div>
                        )}
                      />

                      <Column
                        field="assuranceType"
                        header={
                          <div>
                            보증유형
                            <br />
                            보험사
                          </div>
                        }
                        body={({ assuranceType, insuranceCode }) => (
                          <div>
                            {_.get(
                              _.find(
                                PC_BASE_BLUEPRINT.ASSRNC_TY_SE_CODE.valueItems,
                                { value: assuranceType }
                              ),
                              'label'
                            )}
                            <br />
                            {_.get(
                              _.find(codes.insurances, {
                                value: insuranceCode,
                              }),
                              'label'
                            )}
                          </div>
                        )}
                      />

                      <Column
                        field="shopName"
                        header="점검장명"
                        className="text-center"
                        body={({ shopName }) => `${shopName}`}
                      />

                      <Column
                        field="printCount"
                        header="출력횟수"
                        className="text-center"
                        body={({ printCount }) => `${printCount}회`}
                      />
                    </DataTable>
                  </Panel>

                  {currentStatementNumber !== null && (
                    <CheckBaseDataDialog
                      visible
                      userInfo={myInfoLoadable.contents}
                      dataId={currentDataId}
                      statementNumber={currentStatementNumber}
                      onHide={() => {
                        setCurrentStatementNumber(null);
                        setCurrentDataId(null);
                        setSelectedRows([]);
                      }}
                      getList={getList}
                      searchConditions={searchConditions}
                      page={lazyParams.page}
                      rows={lazyParams.rows}
                    />
                  )}

                  {originStatementNumber !== null && (
                    <RecheckBaseDataDialog
                      visible
                      userInfo={myInfoLoadable.contents}
                      dataId={currentDataId}
                      originStatementNumber={originStatementNumber}
                      onHide={async () => {
                        setOriginStatementNumber(null);
                        setCurrentDataId(null);
                        await getList(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                        setSelectedRows([]);
                      }}
                    />
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PerformanceCheckList, comparisonFn);
